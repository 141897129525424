import React, { useEffect, useState } from 'react';
import './TopNavBar.css'
import { NavLink, useNavigate } from 'react-router-dom';
import downArrowIcon from "../../assets/sidebar/down-Arrow.png";
import upArrow from "../../assets/sidebar/upArrow.png";
import { showAlert } from '../../utils/sweetAlert';
import Streetlights from "../../assets/sidebar/Streetlights.png";

const TopNavBar: React.FC = () => {
    const [open, setOpen] = useState<boolean>(false);


    const toggleMenu = () => {
        setOpen(!open);
    }

    return (
        <nav>
            <div className="mobile_nav">
                <Logo />
                <button type="button" onClick={toggleMenu}>
                    <MenuIcon open={open} />
                    <CloseIcon open={open} />
                </button>
            </div>

            <List open={open} />
        </nav>
    );
}

const Logo: React.FC = () => {
    return (
        <div className="topnav">
            <a className="topnavactive">
                <img src={Streetlights} alt="Streetlights" className="streetlights" />
            </a>
        </div>
    );
}

interface ListProps {
    open: boolean;
}

const List: React.FC<ListProps> = ({ open }) => {
    const navigate = useNavigate();
    const [activeMenu, setActiveMenu] = useState(() => {
        return localStorage.getItem("activeMenu") || "Dashboard";
    });
    const [isOpenReport, setIsOpenReport] = useState(false);
    const [activeReportMenu, setActiveReportMenu] = useState(() => {
        return localStorage.getItem("activeReportMenu") || "";
    });

    useEffect(() => {
        localStorage.setItem("activeMenu", activeMenu);
        localStorage.setItem("activeReportMenu", activeReportMenu);
    }, [activeMenu, activeReportMenu]);

    const handleReportMenuClick = (menu: string) => {
        localStorage.setItem("activeReportMenu", menu);
        setActiveReportMenu(menu);
        setIsOpenReport(false);
    };

    const toggleReport = () => {
        setIsOpenReport(!isOpenReport);
    };

    const handleMenuClick = async (menu: string) => {
        localStorage.setItem("activeMenu", menu);
        setActiveMenu(menu);
        if (menu != "Reports") {
            setIsOpenReport(false);
            setActiveReportMenu("");
            localStorage.setItem("activeReportMenu", '');
        }
    };

    const confirmLogout = async () => {
        console.log("logout");
        const isComfirm = await showAlert("Please Confirm", "Are you sure you want to logout?", "Yes", "No")
        if (isComfirm) {
            navigate("/");
        }
    };

    return (
        <ul className={open ? "show" : ""}>

            <li>
                <NavLink
                    to="/layout/dashboard"
                    className="menu-button"
                    onClick={() => handleMenuClick("Dashboard")}
                    style={{
                        borderRadius: activeMenu === "Dashboard" ? "12px" : "0px",
                        transition: "width 0.3s ease",
                        color: activeMenu === "Dashboard" ? "#fff" : "gray",
                    }}
                >
                    Dashboard
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/layout/files"
                    className="menu-button"
                    onClick={() => handleMenuClick("File")}
                    style={{
                        borderRadius: activeMenu === "File" ? "12px" : "0px",
                        transition: "width 0.3s ease",
                        color: activeMenu === "File" ? "#fff" : "gray",
                    }}
                >
                    Files
                </NavLink>
            </li>
            <li>
                <NavLink
                    to="/layout/pending-query"
                    className="menu-button"
                    style={{
                        borderRadius: activeMenu === "PendingQuery" ? "12px" : "0px",
                        transition: "width 0.3s ease",
                        color: activeMenu === "PendingQuery" ? "#fff" : "gray",
                    }}
                    onClick={() => handleMenuClick("PendingQuery")}
                >
                    Pending Query
                </NavLink>
            </li>
            <li onClick={() => handleMenuClick("Reports")}>
                <a className="menu-button" onClick={toggleReport}
                    style={{
                        borderRadius: activeMenu === "Reports" ? "12px" : "0px",
                        transition: "width 0.3s ease"
                    }}>
                    {open && <span style={{ color: activeMenu === "Reports" ? "#fff" : "gray", }}>Reports</span>}
                    {open && (
                        <img
                            src={isOpenReport ? downArrowIcon : upArrow} alt="downarrow" className="right-arrow"
                        />
                    )}
                    {" "}
                </a>
            </li>
            {isOpenReport && (
                <ul className={!open ? "report-menu-open" : "dropdown-menu"}>
                    <li style={{
                        borderRadius: activeReportMenu === "EODReport" ? "12px" : "0px",
                        transition: "width 0.3s ease",
                        color: activeReportMenu === "EODReport" ? "#fff" : "gray",
                    }}>
                        <NavLink to="/layout/eod-report" onClick={() => handleReportMenuClick("EODReport")}><span style={{ color: activeReportMenu === "EODReport" ? "#fff" : "gray" }}>EOD Report</span></NavLink>
                    </li>
                    <li style={{
                        borderRadius: activeReportMenu === "MISReport" ? "12px" : "0px",
                        transition: "width 0.3s ease",
                        color: activeReportMenu === "MISReport" ? "#fff" : "gray",
                    }}>
                        <NavLink to="/layout/mis-report" onClick={() => handleReportMenuClick("MISReport")}><span style={{ color: activeReportMenu === "MISReport" ? "#fff" : "gray" }}>MIS Report</span></NavLink>
                    </li>
                </ul>
            )}
            <li>
                <NavLink
                    className="menu-button"
                    to="/layout/adduser"
                    onClick={() => handleMenuClick("UserAdd")}
                    style={{
                        borderRadius: activeMenu === "UserAdd" ? "12px" : "0px",
                        transition: "width 0.3s ease"
                    }}
                >
                    {open && (
                        <span style={{ color: activeMenu === "UserAdd" ? "#fff" : "gray", }}>User Management</span>
                    )}
                </NavLink>
            </li>
            <li onClick={() => confirmLogout()}>
                <a
                    onClick={() => handleMenuClick("Logout")}
                    className="menu-button"
                    style={{
                        borderRadius: activeMenu === "Logout" ? "12px" : "0px",
                        transition: "width 0.3s ease"
                    }}>
                    {open && <span style={{ color: activeMenu === "Logout" ? "#fff" : "gray", }}>Logout</span>}
                </a>
            </li>
        </ul>
    );
}

interface MenuIconProps {
    open: boolean;
}

const MenuIcon: React.FC<MenuIconProps> = ({ open }) => {
    return (
        <svg className={open ? "close" : "menu"} fill="#ffffff" viewBox="0 0 448 512" width="30" xlinkTitle="bars">
            <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
        </svg>
    );
}

const CloseIcon: React.FC<MenuIconProps> = ({ open }) => {
    return (
        <svg className={open ? "menu" : "close"} fill="#ffffff" viewBox="0 0 512 512" width="40" xlinkTitle="window-close">
            <path d="M464 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-83.6 290.5c4.8 4.8 4.8 12.6 0 17.4l-40.5 40.5c-4.8 4.8-12.6 4.8-17.4 0L256 313.3l-66.5 67.1c-4.8 4.8-12.6 4.8-17.4 0l-40.5-40.5c-4.8-4.8-4.8-12.6 0-17.4l67.1-66.5-67.1-66.5c-4.8-4.8-4.8-12.6 0-17.4l40.5-40.5c4.8-4.8 12.6-4.8 17.4 0l66.5 67.1 66.5-67.1c4.8-4.8 12.6-4.8 17.4 0l40.5 40.5c4.8 4.8 4.8 12.6 0 17.4L313.3 256l67.1 66.5z" />
        </svg>
    );
}

export default TopNavBar;