// Dashboard.js
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Dashboard.css";
import assignedIcon from "../../../assets/dashboard/assigned-file-icon.png";
import raiseQueryicon from "../../../assets/dashboard/raise-Query-icon.png";
import pendingAdvIcon from "../../../assets/dashboard/pending-adv-icon.png";
import completedFileIcon from "../../../assets/dashboard/completed-file-icon.png";
import calendarIcon from "../../../assets/dashboard/calendar-icon.png";
import { BsChevronRight } from "react-icons/bs";
import Header from "../../Layout/Header";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { callApi } from "../../../utils/Api";
import Select from "react-select";
import $ from "jquery";
import "select2";
import "select2/dist/js/select2";
import "select2/dist/css/select2.min.css";
import { isEmpty } from "lodash";
import { showError, showSuccess } from "../../../utils/sweetAlert";
interface Branch {
  bank_id: string;
}

interface DashboardProps {
  setLoading: (loading: boolean) => void;
}

const Dashboard: React.FC<DashboardProps> = ({ setLoading }) => {
  const navigate = useNavigate();
  const [toDate, setToDate] = useState<string>("");
  const [fromDate, setFromDate] = useState<string>("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [showToDateCalendar, setShowToDateCalendar] = useState(false);
  const [showFromDateCalendar, setShowFromDateCalendar] = useState(false);
  const toDateRef = useRef<HTMLDivElement | null>(null);
  const fromDateRef = useRef<HTMLDivElement | null>(null);
  const [bankList, setBankList] = useState([]);
  const [filteredBankList, setFilteredBankList] = useState([]);
  const [selectBankId, setSelectBankId] = useState([]);
  const [bankId, setBankId] = useState([]);
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const selectRef = useRef<HTMLSelectElement>(null);
  const selectBranchRef = useRef<HTMLSelectElement>(null);
  let select2Instance: any = null;
  const [bankBranchList, setBankBranchList] = useState([]);
  const [bankProductList, setBankProductList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [filterBankBranchList, setFilterBankBranchList] = useState([]);
  const [filterBranchList, setFilterBranchList] = useState([]);
  const [filterBankProductList, setFilterBankProductList] = useState([]);
  const [selectedBranchItems, setSelectedBranchItems] = useState([]);
  const [mainReportDetails, setMainReportDetails] = useState<
    { total: any; status: any }[]
  >([]);
  const [isNotValid, setIsNotValid] = useState(false);
  const [selectedProduct, setselectedProduct] = useState("");

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        toDateRef.current &&
        !(toDateRef.current as HTMLDivElement).contains(event.target as Node)
      ) {
        setShowToDateCalendar(false);
      }
      if (
        fromDateRef.current &&
        !(fromDateRef.current as HTMLDivElement).contains(event.target as Node)
      ) {
        setShowFromDateCalendar(false);
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const storedUserDetails: any = sessionStorage.getItem("userDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const selectedBanksIds = userDetails.bank_id;
  const selectedBranchsIds = userDetails.bank_branch_id;
  const selectedProductId = userDetails.selected_product_id;
  const user_type = userDetails.user_type;

  useEffect(() => {
    getBankBranches();
    getBanks();
    getBankproduct();
    searchBranchStatus("onload");
  }, []);

  const getBanks = () => {
    callApi("GET", "bank_get.php")
      .then((res: any) => res.data)
      .then(async (result: any) => {
        const data = result.data;
        if (user_type == "0") {
          setBankList(data);
        } else {
          const filteredBanks = data.filter((bank: any) =>
            selectedBanksIds.includes(bank.id)
          );
          setBankList(filteredBanks);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getBankBranches = () => {
    callApi("GET", "bank_branch_get.php")
      .then((res) => res.data)
      .then((result) => {
        const data = result.data;
        if (user_type == "0") {
          setBankBranchList(data);
          console.log("user_type______0");
        } else {
          const filteredBanks = data.filter((bank: any) =>
            selectedBranchsIds.includes(bank.id)
          );
          setBankBranchList(filteredBanks);
          console.log(
            "user_type______branch",
            selectedBranchsIds,
            filteredBanks
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getBankproduct = () => {
    callApi("GET", "bank_product_get.php")
      .then((res) => res.data)
      .then((result) => {
        const data = result.data;
        if (user_type == "0") {
          setBankProductList(data);
        } else {
          const filteredproduct = data.filter((bank: any) =>
            selectedProductId.includes(bank.id)
          );
          setBankProductList(filteredproduct);
          console.log(
            "product---------------",
            selectedProductId,
            filteredproduct
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // console.log("selectedItems____", bankList, filteredBankList, bankBranchList);
  }, [selectedItems, fromDate]);

  useEffect(() => {
    console.log("selectBankId____", bankId, selectBankId);
  }, [
    filteredBankList,
    selectBankId,
    bankList,
    bankBranchList,
    branchList,
    selectedBranch,
    selectedBranchItems,
    bankId,
  ]);

  const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Adding padding if month is single digit
    const day = date.getDate().toString().padStart(2, "0"); // Adding padding if day is single digit
    return `${day}-${month}-${year}`;
  };

  const parseFormattedDate = (formattedDate: string): Date => {
    const parts = formattedDate.split("-");
    const day = parseInt(parts[0]);
    const month = parseInt(parts[1]) - 1; // Month is zero-based
    const year = parseInt(parts[2]);
    return new Date(year, month, day);
  };

  const handleFromDateChange = (date: Date | null) => {
    if (date) {
      const selectedFromDate = date;
      const formattedDate = formatDate(selectedFromDate); // Format date function
      if (!toDate || parseFormattedDate(fromDate) >= selectedFromDate) {
        setFromDate(formattedDate);
        setShowFromDateCalendar(false);
        console.log("fromdate", formattedDate);
      } else {
        showError(
          "Failed!",
          "Selected StartDate must be less than or equal to EndDate"
        );
        console.error("Selected fromDate must be less than or equal to toDate");
      }
    }
  };

  const handleToDateChange = (date: Date | null) => {
    if (date) {
      const selectedToDate = date;
      const formattedDate = formatDate(selectedToDate); // Format date function
      if (!fromDate || parseFormattedDate(fromDate) <= selectedToDate) {
        setToDate(formattedDate);
        setShowToDateCalendar(false);
        console.log("todate", formattedDate);
      } else {
        showError(
          "Failed!",
          "Selected EndDate must be greater than or equal to StartDate"
        );
        console.error(
          "Selected toDate must be greater than or equal to fromDate"
        );
      }
    }
  };

  const handleBankChange = (e: any, type: any) => {
    const { name, id } = e;
    console.log("handleBankChange______", e.id, type);

    let selectedBankIds: any = [];
    selectedBankIds.push(id);
    filterProduct(id);
    filterBranches(id);
    if (id == "all" && user_type == "1") {
      setSelectBankId(selectedBanksIds);
      // setSelectedBranchItems([]);
    } else if (id == "all" && user_type == "0") {
      setSelectBankId([]);
      setSelectedBranchItems([]);
      setFilterBranchList([]);
    } else {
      setSelectBankId(selectedBankIds);
    }
    if (user_type == "0") {
      const filteredBranches = bankBranchList.filter(
        (branch: any) => branch.bank_id == id
      );
      setFilterBankBranchList(filteredBranches);
    }
  };

  const filterProduct = (bankid: any) => {
    if (bankid) {
      const filteredProduct = bankProductList.filter(
        (branch: any) => branch.bank_id == bankid
      );
      setFilterBankProductList(filteredProduct);
      console.log("filteredProduct", bankid, filteredProduct);
    } else {
      setselectedProduct("");
    }
  };

  const handleProductChange = (e: any, type: any) => {
    const { product_id, id } = e;
    if (product_id != "all") {
      setselectedProduct(product_id);
      filterBranchesproduct(id);
    } else {
      setselectedProduct("");
    }
  };
  const filterBranches = (bankid: any) => {
    const filteredBranches = bankBranchList.filter(
      (branch: any) => branch.bank_id === bankid
    );
    setFilterBankBranchList(filteredBranches);
    console.log("filteredBranches", filteredBranches);
  };

  const filterBranchesproduct = (productid: any) => {
    let bankids: any = selectBankId;
    let bankidArray = bankids[0];
    let firstbankId = bankidArray;
    const filteredBranches = filterBankBranchList.filter((branch: any) => {
      return branch.product_id == productid && branch.bank_id == firstbankId;
    });
    console.log(
      "filterBranchesproduct_______",
      productid,
      firstbankId,
      filteredBranches
    );

    setFilterBranchList(filteredBranches);

    // if (filteredBranches.length == 0) {
    //     setSelectedBranchItems([]);
    // }
  };

  const handleBranchChange = (e: any) => {
    const { name, id } = e;
    let selectedBranchIds: any = [];
    selectedBranchIds.push(id);
    if (id == "all" && user_type == "1") {
      setSelectedBranchItems(selectedBranchsIds);
    } else if (id == "all" && user_type == "0") {
      setSelectedBranchItems([]);
    } else {
      setSelectedBranchItems(selectedBranchIds);
    }
  };

  const searchBranchStatus = (type: any) => {
    // console.log("searchBranchStatus-----", fromDate, toDate, selectedItems, selectedBranchItems);
    // if (type == "search") {
    //     if (isEmpty(fromDate) || isEmpty(toDate)) {
    //         setIsNotValid(true);
    //     } else {
    //         setIsNotValid(false);
    //         getMainReportDetails();
    //     }
    // } else {
    //     getMainReportDetails();
    // }
    getMainReportDetails();
  };

  const getMainReportDetails = () => {
    const storedUserDetails: any = sessionStorage.getItem("userDetails");
    const userDetails = JSON.parse(storedUserDetails);
    const userId = userDetails.userId;
    const loginBankIds = userDetails.bank_id;
    const loginBranchIds = userDetails.bank_branch_id;
    console.log("user", loginBankIds, loginBranchIds);
    let request = {
      fromdate: fromDate,
      todate: toDate,
      bankname: selectBankId.length > 0 ? selectBankId : loginBankIds,
      bankbranch:
        selectedBranchItems.length > 0 ? selectedBranchItems : loginBranchIds,
      userId: userId,
      product_id: selectedProduct,
    };
    console.log("request", request);
    setLoading(true);
    callApi("POST", "bank_url_main_report.php", request)
      .then((res) => res.data)
      .then((response) => {
        if (response.result == true) {
          setLoading(false);
          setMainReportDetails(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const navigateTo = (location: any, status: any) => {
    const storedUserDetails: any = sessionStorage.getItem("userDetails");
    const userDetails = JSON.parse(storedUserDetails);
    const userId = userDetails.userId;
    const selectedBankIds = userDetails.bank_id;
    const selectedBranchIds = userDetails.bank_branch_id;
    const data = {
      status: status,
      fromdate: fromDate,
      todate: toDate,
      bankname: selectBankId.length > 0 ? selectBankId : selectedBanksIds,
      bankbranch:
        selectedBranchItems.length > 0
          ? selectedBranchItems
          : selectedBranchsIds,
      userId: userId,
      product_id: selectedProduct,
      page_id: "1",
      row_count: "20",
    };
    navigate(`/layout/${location}`, { state: { data } });
  };

  const banksoptions = [
    // { id: "-1", name: "Select bank" }, // Placeholder option
    { id: "all", name: "All" },
    ...bankList,
  ];

  const filteredBranchesoptions = [
    // { id: "-1", name: "Select Branch" },
    { id: "all", name: "All" },
    ...filterBranchList,
  ];

  const filteredProduct = [
    // { product_id: "-1", product_name: "Select Product" },
    { product_id: "all", product_name: "All" },
    ...filterBankProductList,
  ];

  let bankids: any[] = selectBankId;
  let bankidArray = bankids.length > 0 ? bankids[0] : "";
  let firstbankId = bankidArray;

  let branchids: any = selectBankId;
  let branchidArray = branchids[0];
  let firstbranchId = branchidArray;

  return (
    <>
      <Header title="Branch File Status" location="main" path="dash" />
      <div className="container mobile-view-dash-filter">
        <div className="card">
          <div className="card-content">
            <div className="row mt-2 mobile-view-search">
              <div className="col-md-2">
                <label>Start Date</label>
                <div className="form-field" ref={fromDateRef}>
                  <input
                    type="text"
                    id="fromdate"
                    placeholder="DD-MM-YYYY"
                    value={fromDate}
                    readOnly
                    onClick={() =>
                      setShowFromDateCalendar(!showFromDateCalendar)
                    }
                  />
                  <img
                    src={calendarIcon}
                    alt="calendar"
                    onClick={() =>
                      setShowFromDateCalendar(!showFromDateCalendar)
                    }
                  />
                  {showFromDateCalendar && (
                    <DatePicker
                      onChange={handleFromDateChange}
                      inline
                      calendarClassName="white-background"
                      popperPlacement="bottom-start"
                    />
                  )}
                </div>
                {isNotValid && isEmpty(fromDate) ? (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-2">
                <label>End Date</label>
                <div className="form-field" ref={toDateRef}>
                  <input
                    type="text"
                    id="todate"
                    placeholder="DD-MM-YYYY"
                    value={toDate}
                    readOnly
                    onClick={() => setShowToDateCalendar(!showToDateCalendar)}
                  />
                  <img
                    src={calendarIcon}
                    alt="calendar"
                    onClick={() => setShowToDateCalendar(!showToDateCalendar)}
                  />
                  {showToDateCalendar && (
                    <DatePicker
                      onChange={handleToDateChange}
                      inline
                      calendarClassName="white-background"
                      popperPlacement="bottom-start"
                    />
                  )}
                </div>
                {isNotValid && isEmpty(toDate) ? (
                  <>
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Required Field
                    </p>
                  </>
                ) : (
                  ""
                )}
              </div>
              <div className="col-md-2">
                <label>Select Bank</label>
                <div className="select-field">
                  {banksoptions &&
                    banksoptions.length > 0 &&
                    banksoptions.map((opt: any) => {
                      opt.label = opt.name;
                      opt.value = opt.id;
                    }) && (
                      <Select
                        options={banksoptions}
                        name="bank_id"
                        value={banksoptions.filter(
                          (option: any) => option.id == firstbankId
                        )}
                        onChange={(e: any) => handleBankChange(e, "bank")}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-2">
                <label>Select Product</label>
                <div className="select-field">
                  {filteredProduct &&
                    filteredProduct.map((opt: any) => {
                      opt.label = opt.product_name;
                      opt.value = opt.product_id;
                    }) && (
                      <Select
                        options={filteredProduct}
                        name="product_id"
                        value={filteredProduct.find(
                          (o: any) => o.product_id == selectedProduct
                        )}
                        onChange={(e) => handleProductChange(e, "product_id")}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-2">
                <label>Select Branch</label>
                <div className="select-field">
                  {filteredBranchesoptions &&
                    filteredBranchesoptions.length > 0 &&
                    filteredBranchesoptions.map((opt: any) => {
                      opt.label = opt.name;
                      opt.value = opt.id;
                    }) && (
                      <Select
                        options={filteredBranchesoptions}
                        name="branch"
                        value={filteredBranchesoptions.find(
                          (o: any) => o.value == firstbranchId
                        )}
                        onChange={(e) => handleBranchChange(e)}
                      />
                    )}
                </div>
              </div>
              <div className="col-md-2 formsubmit">
                <label></label>
                <button
                  className="dash-sumbit-btn"
                  onClick={() => searchBranchStatus("search")}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-5 mobile-view-files-list">
          <div className="col-md-3 col-sm-12 col-xs-12">
            <div
              className="file-card"
              onClick={() =>
                navigateTo(
                  `total-assigned-files/${mainReportDetails[0]?.status}`,
                  mainReportDetails[0]?.status
                )
              }
            >
              <div className="d-flex">
                <img src={assignedIcon} alt="assigned files" />
                <p className="file-title">Total Assigned Files</p>
              </div>
              <div>
                <p className="file-count">
                  {mainReportDetails?.length > 0
                    ? mainReportDetails[0]?.total
                    : "0"}
                </p>
              </div>
              <div className="view-all-text-div">
                <p className="view-all-text">
                  View All{" "}
                  <BsChevronRight style={{ width: "10px", height: "18px" }} />
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <div
              className="file-card"
              onClick={() =>
                navigateTo(
                  `query-raised-files/${mainReportDetails[1]?.status}`,
                  mainReportDetails[1]?.status
                )
              }
            >
              <div className="d-flex">
                <img src={raiseQueryicon} alt="assigned files" />
                <p className="file-title">Query Raised</p>
              </div>
              <div>
                <p className="file-count">
                  {mainReportDetails?.length > 0
                    ? mainReportDetails[1]?.total
                    : "0"}
                </p>
              </div>
              <div className="view-all-text-div">
                <p className="view-all-text">
                  View All{" "}
                  <BsChevronRight style={{ width: "10px", height: "18px" }} />
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <div
              className="file-card"
              onClick={() =>
                navigateTo(
                  `pending-adv-files/${mainReportDetails[2]?.status}`,
                  mainReportDetails[2]?.status
                )
              }
            >
              <div className="d-flex">
                <img src={pendingAdvIcon} alt="assigned files" />
                <p className="file-title">Pending From Advocate Office</p>
              </div>
              <div>
                <p className="file-count">
                  {mainReportDetails?.length > 0
                    ? mainReportDetails[2]?.total
                    : "0"}
                </p>
              </div>
              <div>
                <p className="view-all-text">
                  View All{" "}
                  <BsChevronRight style={{ width: "10px", height: "18px" }} />
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-12 col-xs-12">
            <div
              className="file-card"
              onClick={() =>
                navigateTo(
                  `completed-files/${mainReportDetails[3]?.status}`,
                  mainReportDetails[3]?.status
                )
              }
            >
              <div className="d-flex">
                <img src={completedFileIcon} alt="assigned files" />
                <p className="file-title">Completed Files</p>
              </div>
              <div>
                <p className="file-count">
                  {mainReportDetails?.length > 0
                    ? mainReportDetails[3]?.total
                    : "0"}
                </p>
              </div>
              <div className="view-all-text-div">
                <p className="view-all-text">
                  View All{" "}
                  <BsChevronRight style={{ width: "10px", height: "18px" }} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
