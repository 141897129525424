import "./Header.css";
import backArrowIcon from "../../assets/dashboard/back-arrow-icon.png";
import { useNavigate } from "react-router-dom";
import Topnav from './Topnav';
import TopNavBar from "./TopNavBar";
import search from "../../assets/Header/search.svg"
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { callApi } from "../../utils/Api";
import { isEmpty } from "lodash";

interface HeaderProps {
    title: string;
    location: string;
    path: string;
}

function Header({ title, location, path }: HeaderProps) {
    const { id } = useParams();
    const navigate = useNavigate();
    const [file_id, setFileid] = useState('');
    const [searchinput, setSearchInput] = useState("")

    const navigateTo = (path: any) => {
        navigate(-1);
    }

    useEffect(() => {
        console.log("id___________________", id);
        const storedUserDetails: any = sessionStorage.getItem('userDetails');
        const userDetails = JSON.parse(storedUserDetails);
        const userId = userDetails.userId;
        const selectedBankIds = userDetails.bank_id;
        const selectedBranchIds = userDetails.bank_branch_id;
        const request = {
            search: id,
            bankname: selectedBankIds,
            bankbranch: selectedBranchIds
        }
        if(!isEmpty(id)){
            callApi("post", "bank_url_search.php", request)
            .then((res: any) => res.data)
            .then((response: any) => {
                if (response.data) {
                    setFileid(response.data[0].file_id);
                    console.log("files___", file_id);
                }
            })
        }
    }, [id]);
    const handlesearch = () => {
        const data = searchinput;
        navigate("/layout/searchreport", { state: { data } });
    }
    const handlesearchchange = (e: any) => {


        setSearchInput(e.target.value);
    }
    const handleKeyPress = (event: any) => {
        const keyCode = event.keyCode || event.which;
        if (keyCode === 13) {
            handlesearch()
        }
    };
    return (
        <>
            {/* <Topnav /> */}
            <div className="mobile_nav_bar">
                <TopNavBar />
            </div>
            <div className="header mt-4 row">
                <div className="dropdown title col-md-6">
                    <div className="row">
                        {location != "main" &&
                            <div className="col-md-1 col-sm-1 col-xs-1 col-1 back-button">
                                <img src={backArrowIcon} alt="back-arrow" className='back-arrow' onClick={() => navigateTo(path)} />
                            </div>
                        }
                        <div className="col-md-11 col-sm-11 col-xs-11 col-11">
                            <h4 className="mobile-view-header-title">{title}</h4>
                            {path == "filedash" && <p style={{ fontSize: "24px" }}>File No: {file_id}</p>}
                        </div>
                    </div>
                </div>
                <div className="search-input col-md-6" onKeyPress={handleKeyPress}>
                    <img src={search} alt="search" style={{ backgroundSize: "20px" }} onClick={() => handlesearch()}></img>
                    <input type="text" placeholder="Search by APP ID or LAN No" style={{ border: "none", width: "80%", outline: "none" }} name="search" onChange={(e) => handlesearchchange(e)} />
                </div>
            </div>
        </>
    );
}

export default Header;
