import axios from "axios";
//  export const API_ENDPOINT = "http://localhost/law_hands/api/";
 export const API_ENDPOINT = "https://lawhands.org/law_hands/api/";
// export const API_ENDPOINT = "http://13.232.234.192/law_hands/api/";

export async function callApi(method: string, path: string, data?: any) {
    if (method.toUpperCase() === "POST") {
        return await axios.post(API_ENDPOINT + path, data);
    } else if (method.toUpperCase() === "DELETE") {
        return await axios.delete(API_ENDPOINT + path);
    } else {
        return await axios.get(API_ENDPOINT + path);
    }
}
