import React, { useEffect, useState } from 'react';
import pageleft from "../../assets/dashboard/pagination-left.png";
import pageright from "../../assets/dashboard/pagination-right.png";
import '../Pages/Dashboard/sub-Pages/index.css';
import ReactPaginate from 'react-paginate';
import { useNavigate, useLocation } from "react-router-dom";
import Header from '../../../src/components/Layout/Header';
import * as XLSX from 'xlsx';
import { getFileStatus } from "../../../src/utils/getFileStatus";
import { callApi } from '../../utils/Api';
import { request } from 'http';
import { showError } from '../../utils/sweetAlert';

interface FileData {
    serial_no: string;
    name: string;
    application_no: string;
    file_no: string;
    status: string;
    file_id: string;
}

function Searchreport() {
    const navigate = useNavigate();
    const location = useLocation();
    const navigationData = location.state?.data;

    const [files, setFiles] = useState<FileData[]>([]);
    const [limitList, setLimitList] = useState(['5', '10', '15', '20', 'All']);
    const [selectLimit, setSelectLimit] = useState(5);
    const [currentPage, setCurrentPage] = useState(0);
    const [selectedlimit, setSelectedlimit] = useState('');
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [dates, setDates] = useState("");
    const [branch, setBranch] = useState("");

    const handlepageListChange = (event: any) => {
        if (event.target.value == "All") {
            setSelectedlimit(event.target.value);
            setSelectLimit(event.target.value);
            setItemsPerPage(files.length);
        } else {
            setSelectLimit(event.target.value);
            setItemsPerPage(event.target.value);
        }
    };


    useEffect(() => {
        // setFiles(navigationData.data);
        // setDates(navigationData.date);
        // setBranch(navigationData.branch);

        console.log("FilesSubReport Files---", navigationData);
        const storedUserDetails: any = sessionStorage.getItem('userDetails');
        const userDetails = JSON.parse(storedUserDetails);
        const userId = userDetails.userId;
        const selectedBankIds = userDetails.bank_id;
        const selectedBranchIds = userDetails.bank_branch_id;
        const request = {
            search: navigationData,
            bankname: selectedBankIds,
            bankbranch: selectedBranchIds
        }

        callApi("post", "bank_url_search.php", request)
            .then((res: any) => res.data)
            .then((response: any) => {
                if (response.status === "200") {
                    setFiles(response.data);
                } else {
                    showError("FAILED!", "Failed to get Details");
                }

            })
    }, [navigationData]);

    useEffect(() => {
        console.log("updated FilesSubReport Files---", files);
    }, [files, itemsPerPage]);

    const pageCount = Math.ceil(files.length / itemsPerPage);

    const handlePageClickprevious = () => {
        setCurrentPage(currentPage - 1);
        console.log(currentPage)
    };
    const handlePageClicknext = () => {
        setCurrentPage(currentPage + 1);
        console.log(currentPage)
    };
    const indexOfLastItem = (currentPage + 1) * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = files;

    const routeToDashboard = (id: any) => {
        navigate(`/layout/filedash/${id}`);
    }

    return (
        <>
            <Header title="Seach Report" location="sub" path="searchreport" />
            <div className="container mt-5 mobile-view-file-table">
                <div className="card">
                    <div className="table-container pl-3 pr-3">
                        <table>
                            <thead>
                                <tr className='head-row'>
                                    <td>S.No</td>
                                    <td>Name</td>
                                    <td>Application No</td>
                                    <td>File No</td>
                                    <td>Status</td>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems?.length > 0 ? currentItems.map((file: any, index: any) => {
                                    return (
                                        <tr className='body-row' key={index} onClick={() => routeToDashboard(file.id)}>
                                            <td>{file.s_no}</td>
                                            <td>{file.borrower_name}</td>
                                            <td><div className='app-no mt-3'><p>{file.app_id ? file.app_id : "N/A"}</p></div></td>
                                            <td>{file.file_id}</td>
                                            <td>
                                                {getFileStatus(file?.file_status)}
                                            </td>
                                        </tr>
                                    );
                                }) : <><tr><td colSpan={4} style={{ textAlign: "center", justifyItems: "center", color: "gray", fontSize: "20px" }}>No data available</td></tr></>}
                            </tbody>
                        </table>
                        <div className="pagination-and-info">
                            {/* Pagination */}
                            <div className="pagination">
                                <button onClick={handlePageClickprevious} style={{ height: "40px", width: "40px", borderRadius: "5px", padding: "0px 0px 5px 0px", backgroundColor: "white" }} disabled={currentPage === 0}>
                                    <img
                                        src={pageleft}
                                        alt="left arrow"
                                        style={{ marginRight: "5px", width: "7px", height: "12px" }}
                                    />
                                </button>
                                <span style={{ fontSize: "18px", padding: "0px 8px 8px 0px", backgroundColor: "white", margin: "5px 0px 0px 5px", display: 'flex' }}>
                                    {pageCount === 0 ? "0" : currentPage + 1}
                                    <p style={{ padding: "0px 10px 0px 10px" }}>/</p>
                                    {pageCount}
                                </span>
                                <button onClick={handlePageClicknext} style={{ height: "40px", width: "40px", borderRadius: "5px", padding: "0px 0px 5px 0px", backgroundColor: "white" }} disabled={currentPage === pageCount - 1}>
                                    <img
                                        src={pageright}
                                        alt="right arrow"
                                        style={{ marginRight: "5px", width: "7px", height: "12px" }}
                                    />
                                </button>
                            </div>
                            <div className="row-per-page mt-5">
                                <span style={{ marginRight: "10px" }}>Rows per page</span>
                                <select className='select' aria-placeholder='Choose Branch' value={selectLimit} onChange={handlepageListChange} style={{ height: "30px", width: "48px", padding: "0px 2px 0px" }}>
                                    {limitList.map((limitValue, index) => (
                                        <option key={index} value={limitValue}>
                                            {limitValue}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};
export default Searchreport;
