import React, { useEffect, useState } from 'react';
import Header from '../../../Layout/Header';
import FileIndex from './index';
interface DashboardProps {
    setLoading: (loading: boolean) => void;
}
const CompletedFiles: React.FC<DashboardProps> = ({ setLoading }) => {
    return (
        <>
            <Header title="Completed Files" location="sub" path="dash" />
            <FileIndex path="Completed Files" setLoading={setLoading} />
        </>
    );
};
export default CompletedFiles;

