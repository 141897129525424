import React, { Component } from "react";
import "./DocumentNumber.css";
interface AllProps {
    documentdetail: any;
    categoryname?: string;
}
export default class DocumentNumber extends Component<AllProps> {
    getClasses = () => {
        if (this.props.categoryname === "Document") {
            return "card-footer bg-purple text-white border-btm-radius";
        } else if (this.props.categoryname === "EC") {
            return "card-footer bg-primary text-white border-btm-radius";
        } else if (this.props.categoryname === "Revenue Record") {
            return "card-footer bg-warning text-white border-btm-radius";
        }
    };
    render() {
        return (
            <div className="col-xl-4 col-md-4">
                <div className="doc-no-card support-bar">
                    <div className="card-body pb-0 text-center">
                        <h6 className="mb-3 pt-4 title">Number Of {this.props.categoryname}</h6>
                        <h2 className="m-4 doc-no-count">
                            {this.props.documentdetail?.total ? this.props.documentdetail?.total : "0"}
                        </h2>
                    </div>
                    <div className={this.getClasses()}>
                        <div className="row text-center mt-4" >
                            <div className="col" style={{ padding: "3%" }}>
                                <h4 className="m-0 text-white">
                                    {this.props.documentdetail?.yettoopen ? this.props.documentdetail?.yettoopen : "0"}
                                </h4>
                                <span>Yet to Open</span>
                            </div>
                            <div className="col" style={{ padding: "3%" }}>
                                <h4 className="m-0 text-white">
                                    {this.props.documentdetail?.inprogress ? this.props.documentdetail?.inprogress : "0"}
                                </h4>
                                <span>In Progress</span>
                            </div>
                            <div className="col" style={{ padding: "3%" }}>
                                <h4 className="m-0 text-white">
                                    {" "}
                                    {this.props.documentdetail?.submitted ? this.props.documentdetail?.submitted : 0}
                                </h4>
                                <span>Submited</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
