import React, { useEffect, useRef } from 'react';
import { Modal, ModalBody, ModalHeader } from 'react-bootstrap';

interface ModalProps {
    show: boolean; // Prop to control modal visibility
    onClose: () => void; // Function to handle modal closing
    title: string; // Title for the modal
    body: string; // Body content for the modal
}

const SuccessModal: React.FC<ModalProps> = ({ show, onClose, title, body }) => {
    const timeoutRef = useRef<NodeJS.Timeout | null>(null); // Initialize timeoutRef with null

    useEffect(() => {
      if (show) {
        // Clear any previous timeout
        if (timeoutRef.current !== null) {
          clearTimeout(timeoutRef.current);
        }
    
        timeoutRef.current = setTimeout(() => {
          onClose();
        }, 2000); // Close modal after 2 seconds
      }
    }, [show, onClose]);

    return (
        <Modal show={show} onHide={onClose} centered> {/* Center the modal */}
            <ModalHeader closeButton>
                <Modal.Title>{title}</Modal.Title>
            </ModalHeader>
            <ModalBody>
                <p>{body}</p>
            </ModalBody>
        </Modal>
    );
};

export default SuccessModal;
