import React, { useState } from 'react';
import '../Login/Login.css';
import { useNavigate } from "react-router-dom";
import { isEmpty } from 'lodash';
import emailicon from '../../assets/login/email_icon.png';
import passicon from '../../assets/login/pass_icon.png';
import showPassIcon from '../../assets/login/eye-off.png';
import hidePassIcon from '../../assets/login/eye-on.png';
import { callApi } from '../../utils/Api';
import SuccessModal from '../../utils/SuccessModal';
import { showError, showSuccess } from "../../utils/sweetAlert";
import Loader from '../../utils/Loader';

const LoginForm = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = React.useState("");
    const [passwordError, setPasswordError] = React.useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalBody, setModalBody] = useState('');
    const [loading, setLoading] = useState(false);
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const handleUsernameChange = (event: any) => {
        setUsername(event.target.value);
      setEmailError("");
    };
    const handlePasswordChange = (event: any) => {
        setPassword(event.target.value);
      setPasswordError("");
    };
    const handleCloseModal = () => {
        setShowSuccessModal(false); // Hide the SuccessModal
    };
    const goToForgot = () => {
        navigate("/forgot-password");
    }
    const handleSubmit = (event: any) => {

        let emailError = "";
        let passwordError = "";

        if (!username) {
            emailError = "Email is required";
          } else if (!/\S+@\S+\.\S+/.test(username)) {
            emailError = "Invalid email format";
          }
      
          if (!password) {
            passwordError = "Password is required";
          }
      
          if (emailError || passwordError) {
            setEmailError(emailError);
            setPasswordError(passwordError);
            return;
          }

        // event.preventDefault();
        const req = {
            email: username,
            password: password
        }
        if (!isEmpty(username) && !isEmpty(password)) {
            setLoading(true);
            callApi("POST", "bank_url_login.php", req)
                .then((res: any) => res.data)
                .then((response: any) => {
                    if (response.status === 200) {
                        setTimeout(() => {
                            setLoading(false);
                        }, 500);
                        // setShowSuccessModal(true);
                        // setModalTitle('Success!');
                        // setModalBody('You have successfully logged in.');
                        setTimeout(() => {
                            showSuccess("Success!", "Login Successfully!");
                        }, 1000);
                        setUsername('');
                        setPassword('');
                        const userDetails = response.data;
                        sessionStorage.setItem('userDetails', JSON.stringify(userDetails));
                        setTimeout(() => {
                            navigate("/layout/dashboard");
                        }, 2000);
                    } else {
                        // setShowSuccessModal(true);
                        // setModalTitle('Error');
                        // setModalBody('Invalid credentials. Please try again.');
                        showError("Failed", "Email & Password are not matched!");
                    }
                })
        } else {
            showError("Failed", "Email or password is empty!");
        }
    };
    return (
        <>
            {/* {loading ? <>
                <Loader />
            </> : <> */}
            <div className='bg-img container-fluid'>
                <div className="card">
                    <div className="row">
                        <div className="col-md-6 col-xl-6"></div>
                        <div className="col-md-6 col-xl-6  pt-5 pr-5">
                            <form className="form" >
                                <h1 className='header-lable'>Login</h1>
                                <div className="form-group">
                                    <div className="input-field d-flex mt-5">
                                        <div className='field-icon'>
                                            <img className='email_icon' src={emailicon} />
                                        </div>
                                        <input
                                            type="text"
                                            id="username"
                                            value={username}
                                            placeholder='Email'
                                            onChange={handleUsernameChange}
                                            autoComplete='off'
                                        />
                                    </div>
                    <div className="text-danger">{emailError}</div>

                                </div>
                                <div className="form-group">
                                    <div className="input-field d-flex mt-4">
                                        <div className='field-icon'>
                                            <img className='pass_icon' src={passicon} />
                                        </div>
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            id="password"
                                            value={password}
                                            placeholder='Password'
                                            onChange={handlePasswordChange}
                                            autoComplete='off'
                                        />
                                        <img
                                            src={showPassword ? hidePassIcon : showPassIcon}
                                            alt={showPassword ? "Hide Password" : "Show Password"}
                                            className={showPassword ? "eye-on" : "eye-off"}
                                            onClick={togglePasswordVisibility}
                                        />
                                    </div>
                    <div className="text-danger">{passwordError}</div>

                                </div>
                                <p className='text-white forgot-password mt-2' onClick={() => goToForgot()}>Forget Password?</p>
                                <button className='mt-4 submit-btn loginbtn' type='button' onClick={handleSubmit}>Login</button>
                            </form>
                        </div>
                    </div>
                </div>
                <SuccessModal
                    show={showSuccessModal}
                    onClose={handleCloseModal}
                    title={modalTitle}
                    body={modalBody}
                />
            </div>
            {/* </>} */}
        </>
    );
};
export default LoginForm;