import React, { useEffect, useState } from 'react';
import Header from '../../../Layout/Header';
import FileIndex from './index';
interface DashboardProps {
    setLoading: (loading: boolean) => void;
}
const TotalAssignedFiles: React.FC<DashboardProps> = ({ setLoading }) => {

    return (
        <>
            <Header title="Total Assigned Files" location="sub" path="dash" />
            <FileIndex path="Total Assigned Files" setLoading={setLoading} />
        </>
    );
};
export default TotalAssignedFiles;
