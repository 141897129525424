import React from "react";
import ExcelJS from "exceljs";
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";

const ExcelUser = ({ data }) => {
    const exportExcelFile = async () => {
        if (!Array.isArray(data)) {
            console.error("Data is not an array:", data);
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Define columns with headers
        worksheet.columns = [
            { header: 'S.No', key: 's_no', width: 10 },
            { header: 'Name', key: 'name', width: 25 },
            { header: 'Mobile Number', key: 'phone_no', width: 20 },
            { header: 'Email ID', key: 'email', width: 30 },
            { header: 'Bank Name', key: 'bank_name', width: 35 },
            { header: 'Branch Name', key: 'branch_name', width: 35 },
            { header: 'Product', key: 'product_name', width: 35 },
        ];

        // Apply alignment and color to header cells
        worksheet.getRow(1).eachCell((cell) => {
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }, // Yellow color
            };
            cell.font = { bold: true, size: 14 }; // Bold and font size 20
        });

        const flattenedData = data.map((file, index) => {
            return {
                s_no: file.s_no,
                name: file.name,
                phone_no: file.phone_no,
                email: file.email,
                bank_name: Array.isArray(file.bank_name) ? file.bank_name.join(', ') : file.bank_name,
                branch_name: Array.isArray(file.branch_name) ? file.branch_name.join(', ') : file.branch_name,
                product_name: Array.isArray(file.product_name) ? file.product_name.join(', ') : file.product_name,
            };
        });

        // Add data rows with custom cell alignment and wrap text
        flattenedData.forEach(item => {
            const row = worksheet.addRow(item);
            row.eachCell((cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
        // Generate Excel file buffer
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `User List ${formattedDate}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <>
            <button className="download-btn" onClick={exportExcelFile}>
                <img className="download-image" src={excelDownloadIcon} alt="Download Excel" />
                Download
            </button>
        </>
    );
};

export default ExcelUser;
