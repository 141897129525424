import React, { useEffect, useState } from "react";
import "../Login/ForgetPassword.css";
import { useNavigate } from "react-router-dom";
import { isEmpty } from "lodash";
import emailicon from "../../assets/login/email_icon.png";
import passicon from "../../assets/login/pass_icon.png";
import showPassIcon from "../../assets/login/eye-off.png";
import hidePassIcon from "../../assets/login/eye-on.png";
import { callApi } from "../../utils/Api";
import SuccessModal from "../../utils/SuccessModal";
import { showError, showSuccess } from "../../utils/sweetAlert";

const ForgetPassword = () => {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  // const [showPassField, setShowPassField] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [emailerror, setemailerror] = useState(false);
  const [passworderror, setpassworderror] = useState(false);
  const [ConfirmPassWorderror, setConfirmPassWorderror] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [PasswordMatch, setPasswordMatch] = useState(false);
  const [forgetemail, setforgetemail] = useState(true);
  const [forgetpass, setForgetpass] = useState(false);
  const [forgetotp, setForgetotp] = useState(false);

  const [formFields, setFormfields] = useState({
    email: "",
    password: "",
    confirmpassword: "",
    otp: "",
  });

  useEffect(() => {
    console.log("emailerror", emailerror);
  }, [emailerror, passworderror]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const [OtpError, setOtpError] = React.useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormfields((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    if (e.target.id === "email") {
      console.log("email validating enter");
      const ValidINPUT = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(
        e.target.value
      );
      console.log(ValidINPUT);
      const endsWithValidDomain =
        e.target.value.endsWith(".com") ||
        e.target.value.toLowerCase().endsWith(".in");
      if (!ValidINPUT && !endsWithValidDomain) {
        console.log("failed email");
        setemailerror(true);
        console.error("Invalid input. Please enter only characters.");
        return;
      } else {
        setemailerror(false);
        setFormfields((prevState) => ({
          ...prevState,
          [e.target.name]: e.target.value,
        }));
      }
    } else if (e.target.id === "password") {
      // const ValidInput = /^(?=.[0-9])(?=.[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(e.target.value);
      const ValidInput = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(
        e.target.value
      );

      if (!ValidInput) {
        setpassworderror(true);
        console.error("passworderror");
        return;
      } else {
        setpassworderror(false);
        setFormfields((prevState) => {
          const updatedState = {
            ...prevState,
            [e.target.name]: e.target.value,
          };
          // if (formFields.password !== formFields.confirmpassword) {
          //   console.log("password",formFields.password,formFields.confirmpassword);
          //   setPasswordMatch(true);
          // } else {
          //   setPasswordMatch(false);
          // }
          return updatedState;
        });
      }
    } else if (e.target.id == "confirmpassword") {
      console.log(" elseif");
      const ValidInput = /^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/.test(
        e.target.value
      );
      if (!ValidInput) {
        console.log(" if");
        setConfirmPassWorderror(true);
        console.error("conform password error");
        return;
      } else {
        console.log(" else");
        setConfirmPassWorderror(false);
        setFormfields((prevState) => {
          const updatedState = {
            ...prevState,
            [e.target.id]: e.target.value,
          };
          console.log("formFields.password",formFields.password);
          console.log("confirmpassword",updatedState.confirmpassword);
          
          

          if (formFields.password !== updatedState.confirmpassword) {
          console.log("show error");
            console.log("confirmpassword",formFields.password,updatedState.confirmpassword);
            setPasswordMatch(true);
          } else {
          console.log("else show error");
            setPasswordMatch(false);
          }
          return updatedState;
        });
      }
    } else {

      setFormfields((prevState) => ({
        ...prevState,
        [e.target.id]: e.target.value,
      }));
      setOtpError("");
      console.log("opt______",e.target.id, e.target.value); 
    }
  };
  const handleCloseModal = () => {
    setShowSuccessModal(false);
  };
  const goToLogin = () => {
    navigate("/");
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (forgetemail == true) {
      if (!isEmpty(formFields.email)) {
        if (emailerror) {
          showError("Failed", "Email or password is not valid!");
        } else {
          const req = {
            email: formFields.email,
          };
          callApi("POST", "otp_send.php", req)
            .then((res: any) => res.data)
            .then((response: any) => {
              if (response.success === true) {
                showSuccess("Success!", response.message);
                setTimeout(() => {
                  //   navigate("/");
                  setForgetotp(true);
                  setforgetemail(false);
                }, 500);
              } else {
                showError("Failed", response.message);
              }
            });
        }
      } else {
        showError("Failed", "Email is empty!");
      }
    } else if (forgetotp == true) {
      if (!isEmpty(formFields.otp)) {
        let OtpError = "";

        if (!formFields.otp) {
          OtpError = "Otp is required";
        } else if (!/^[0-9]+$/.test(formFields.otp)) {
          OtpError = "Invalid Otp format";
        }
        if (OtpError) {
          setOtpError(OtpError);
          return;
        }
        const req = {
          email: formFields.email,
          otp: formFields.otp,
        };
        callApi("POST", "otp_verify.php", req)
          .then((res: any) => res.data)
          .then((response: any) => {
            if (response.success === true) {
              showSuccess("Success!", response.message);
              setTimeout(() => {
                // navigate("/");
                setForgetpass(true);
                setForgetotp(false);
              }, 500);
            } else {
              showError("Failed", response.message);
            }
          });
        // }
      } else {
        showError("Failed", "Verification Code is empty!");
      }
    } else if (forgetpass == true) {
      if (
        !isEmpty(formFields.password) ||
        !isEmpty(formFields.confirmpassword)
      ) {
        // if (emailerror) {
        //   showError("Failed", "Email or password is not valid!");
        // } else {
        const req = {
          email: formFields.email,
          password: formFields.password,
        };
        callApi("POST", "bank_url_forgot_password.php", req)
          .then((res: any) => res.data)
          .then((response: any) => {
            if (response.status_code === "200") {
              showSuccess("Success!", response.message);
              setTimeout(() => {
                navigate("/");
              }, 2000);
            } else {
              showError("Failed", response.message);
            }
          });
        // }
      } else {
        showError("Failed", "Password is empty!");
      }
    }
  };
  return (
    <div className="forgot-bg-img forgot-pass container-fluid">
      <div className="card">
        <div className="row">
          <div className="col-md-6 col-xl-6 forgot_password_image"></div>
          <div className="col-md-6 col-xl-6 forgot-form-container pr-5">
            <form className="forgot-form" onSubmit={handleSubmit}>
              <h1 className="header-lable">Forgot Password</h1>
              {forgetemail == true && (
                <div className="form-group">
                  <div className="input-field d-flex mt-5">
                    <div className="field-icon">
                      <img className="email_icon" src={emailicon} />
                    </div>
                    <input
                      type="text"
                      id="email"
                      value={formFields.email}
                      placeholder="Email"
                      onChange={handleChange}
                      required
                    />
                  </div>
                  {emailerror && formFields.email.length > 0 && (
                    <>
                      <p
                        style={{
                          color: "red",
                          fontSize: "14px",
                          marginLeft: "10px",
                        }}
                      >
                        Please enter a valid email address '.com' (or) '.in'.
                      </p>
                    </>
                  )}
                </div>
              )}

              {forgetotp == true && (
                <div className="form-group">
                  <div className="input-field d-flex mt-5">
                    <div className="field-icon">
                      <img className="email_icon" src={passicon} />
                    </div>
                    <input
                      type="text"
                      id="otp"
                      value={formFields.otp}
                      placeholder="Verification Code"
                      onChange={handleChange}
                      pattern="[0-9]*"
                      title="Please enter only numbers"
                      minLength={4}
                      maxLength={4}
                      required
                    />
                  </div>
                  <div className="text-danger">{OtpError}</div>

                </div>
              )}

              {/* {showPassField &&  */}
              {forgetpass == true && (
                <>
                  <div className="form-group">
                    <div className="input-field d-flex mt-4">
                      <div className="field-icon">
                        <img className="pass_icon" src={passicon} />
                      </div>
                      <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        value={formFields.password}
                        placeholder="Password"
                        onChange={handleChange}
                        required
                        maxLength={8}
                      />
                      <img
                        src={showPassword ? hidePassIcon : showPassIcon}
                        alt={showPassword ? "Hide Password" : "Show Password"}
                        className={showPassword ? "eye-on" : "eye-off"}
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    {passworderror && formFields.password.length > 0 && (
                      <>
                        <p
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "10px",
                            width: "60%",
                          }}
                        >
                          Required 8 characters with minimum of 1 uppercase,1
                          lowercase,1 special character and 1 numeric.
                        </p>
                      </>
                    )}
                  </div>
                  <div className="form-group">
                    <div className="input-field d-flex mt-4">
                      <div className="field-icon">
                        <img className="pass_icon" src={passicon} />
                      </div>
                      <input
                        type={showConfirmPassword ? "text" : "password"}
                        id="confirmpassword"
                        value={formFields.confirmpassword}
                        placeholder="Confirm Password"
                        onChange={handleChange}
                        required
                        maxLength={8}
                      />
                      <img
                        src={showConfirmPassword ? hidePassIcon : showPassIcon}
                        alt={
                          showConfirmPassword
                            ? "Hide Password"
                            : "Show Password"
                        }
                        className={showConfirmPassword ? "eye-on" : "eye-off"}
                        onClick={toggleConfirmPasswordVisibility}
                      />
                    </div>
                    {ConfirmPassWorderror &&
                      formFields.confirmpassword.length > 0 && (
                        <>
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginLeft: "10px",
                              width: "60%",
                            }}
                          >
                            Required 8 characters with minimum of 1 uppercase,1
                            lowercase,1 special character and 1 numeric.
                          </p>
                        </>
                      )}
                    {PasswordMatch && (
                      <>
                        <p
                          style={{
                            color: "red",
                            fontSize: "14px",
                            marginLeft: "10px",
                          }}
                        >
                          Password does not Match
                        </p>
                      </>
                    )}
                  </div>
                </>
              )}

              <p
                className="back-to-text text-white mt-2"
                onClick={() => goToLogin()}
              >
                Back to Login
              </p>
              <button
                className="mt-4 submit-btn"
                type="submit"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </form>
          </div>
        </div>
      </div>
      <SuccessModal
        show={showSuccessModal}
        onClose={handleCloseModal}
        title={modalTitle}
        body={modalBody}
      />
    </div>
  );
};
export default ForgetPassword;
