import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import leftarrow from "../../assets/sidebar/left-arrow.png";
import rightarrow from "../../assets/sidebar/right-arrow.png";
import dashboardActiveIcon from "../../assets/sidebar/dashboard-active-icon.png";
import dashboardIcon from "../../assets/sidebar/dashboard-icon.png";
import filesicon from "../../assets/sidebar/files-icon.png";
import activefilesicon from "../../assets/sidebar/active-file-icon.png";
import pendingqueryicon from "../../assets/sidebar/pending-query-icon.png";
import activependingqueryicon from "../../assets/sidebar/active-pquery-icon.png";
import userManagementIcon from "../../assets/sidebar/user_management_icon.png";
import activeUserManagementIcon from "../../assets/sidebar/active_user_management_icon.png";
import downArrowIcon from "../../assets/sidebar/down-Arrow.png";
import upArrow from "../../assets/sidebar/upArrow.png";
import reportsicon from "../../assets/sidebar/reports-icon.png";
import activereportsicon from "../../assets/sidebar/active-reports-icon.png";
import logoutactive from "../../assets/sidebar/logout.svg";
import logout from "../../assets/sidebar/logout-active.png";
import Streetlights from "../../assets/sidebar/Streetlights.png";
import logo from "../../assets/sidebar/logo.png";
import { NavLink, useNavigate } from "react-router-dom";
import { showAlert } from "../../utils/sweetAlert";

const Sidebar: React.FC = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState(() => {
    return localStorage.getItem("activeMenu") || "Dashboard";
  });
  const [isOpenReport, setIsOpenReport] = useState(false);
  const [activeReportMenu, setActiveReportMenu] = useState(() => {
    return localStorage.getItem("activeReportMenu") || "";
  });
  const [userName, setUsername] = useState("Welcome");

  useEffect(() => {
    // if (activeReportMenu != "") {
    //   // setIsOpenReport(true);
    // }
    const storedUserDetails: any = sessionStorage.getItem('userDetails');
    const userDetails = JSON.parse(storedUserDetails);
    setUsername(userDetails?.userName);
  });

  useEffect(() => {
    localStorage.setItem("activeMenu", activeMenu);
    localStorage.setItem("activeReportMenu", activeReportMenu);
  }, [activeMenu, activeReportMenu]);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const toggleReport = () => {
    setIsOpenReport(!isOpenReport);
  };

  const handleMenuClick = async (menu: string) => {
    localStorage.setItem("activeMenu", menu);
    await setActiveMenu(menu);
    if (menu != "Reports") {
      setIsOpenReport(false);
      setActiveReportMenu("");
      localStorage.setItem("activeReportMenu", '');
    }
  };
  const handleReportMenuClick = (menu: string) => {
    localStorage.setItem("activeReportMenu", menu);
    setActiveReportMenu(menu);
    if (!isOpen) {
      setIsOpenReport(false);
    }
  };

  const confirmLogout = async () => {
    console.log("logout");
    const isComfirm = await showAlert("Please Confirm", "Are you sure you want to logout?", "Yes", "No")
    if (isComfirm) {
      navigate("/");
      localStorage.clear();
      sessionStorage.clear();
    }
  };
  const storedUserDetails: any = sessionStorage.getItem('userDetails');
  const userDetails = JSON.parse(storedUserDetails);
  const userType = userDetails.user_type;

  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <div className="sidebar-header">
        <div className="header-content">
          <div className="sidebar-heading">
            <img src={Streetlights} alt="Streetlights" className="streetlights" />
            <div className="d-flex">
              <img src={logo} alt="Logo" className="profile-logo" />
              {isOpen && <span className="profile-name">{userName}</span>}
            </div>
          </div>
          <div className="sidebar-toggle" onClick={toggleSidebar}>
            {isOpen ? (
              <img src={leftarrow} alt="open" className="arrow-icon" />
            ) : (
              <img src={rightarrow} alt="Close" className="arrow-icon" />
            )}
          </div>
        </div>
      </div>
      <hr className="horizontal-line" />
      <div className="sidebar-header">
        <span className="sidebar-title" style={{ color: "gray" }}>MAIN</span>
      </div>
      <ul className="sidebar-menu" style={{ paddingLeft: isOpen ? "5%" : "15%" }}>
        <li
          style={{
            borderRadius: activeMenu === "Dashboard" ? "12px" : "0px",
            width: activeMenu === "Dashboard" ? "90%" : "auto",
            transition: "width 0.3s ease",
            background: activeMenu === "Dashboard" ? "radial-gradient(100% 100% at 0% 0%, rgba(184, 110, 159, 0.12) 0%, rgba(102, 37, 37, 0.12) 100%)" : "#0F090C",
            padding: isOpen && activeMenu === "Dashboard" ? "5% 0%" : "5% 0%",
          }}>
          <NavLink
            className="menu-button"

            to="/layout/dashboard"
            onClick={() => handleMenuClick("Dashboard")}
          >
            <img
              src={activeMenu === "Dashboard" ? dashboardActiveIcon : dashboardIcon}
              alt="dashboard"
              className="menu-icon-dash"
            />{" "}
            {isOpen && <span style={{ color: activeMenu === "Dashboard" ? "#fff" : "gray", marginLeft: "10px", }}>Dashboard</span>}
          </NavLink>
        </li>
        <li style={{
          background: activeMenu === "File" ? "radial-gradient(100% 100% at 0% 0%, rgba(184, 110, 159, 0.12) 0%, rgba(102, 37, 37, 0.12) 100%)" : "#0F090C",
          borderRadius: activeMenu === "File" ? "12px" : "0px",
          width: activeMenu === "File" ? "90%" : "auto",
          padding: !isOpen && activeMenu === "File" ? "10% 0%" : "5% 0%",
          transition: "width 0.3s ease"
        }}>
          <NavLink
            className="menu-button"
            to="/layout/files"
            onClick={() => handleMenuClick("File")}
          >
            <img src={activeMenu === "File" ? activefilesicon : filesicon} alt="File" className={activeMenu === "File" ? "menu-icon-file-active" : "menu-icon-file"} />{" "}
            {isOpen && <span style={{ marginLeft: "10px", color: activeMenu === "File" ? "#fff" : "gray", }}>Files</span>}
          </NavLink>
        </li>
        <li
          style={{
            background: activeMenu === "PendingQuery" ? "radial-gradient(100% 100% at 0% 0%, rgba(184, 110, 159, 0.12) 0%, rgba(102, 37, 37, 0.12) 100%)" : "#0F090C",
            borderRadius: activeMenu === "PendingQuery" ? "12px" : "0px",
            width: activeMenu === "PendingQuery" ? "90%" : "auto",
            padding: !isOpen && activeMenu === "PendingQuery" ? "10% 0%" : "5% 0%",
            transition: "width 0.3s ease"
          }}>
          <NavLink
            className="menu-button"
            to="/layout/pending-query"
            onClick={() => handleMenuClick("PendingQuery")}
          >
            <img
              src={activeMenu === "PendingQuery" ? activependingqueryicon : pendingqueryicon}
              alt="File"
              className="menu-icon-file"
            />{" "}
            {isOpen && (
              <span style={{ marginLeft: "10px", color: activeMenu === "PendingQuery" ? "#fff" : "gray", }}>Pending Query</span>
            )}
          </NavLink>
        </li>
        <li className="mt-2" onClick={() => handleMenuClick("Reports")}
          style={{
            background: activeMenu === "Reports" ? "radial-gradient(100% 100% at 0% 0%, rgba(184, 110, 159, 0.12) 0%, rgba(102, 37, 37, 0.12) 100%)" : "#0F090C",
            borderRadius: activeMenu === "Reports" ? "12px" : "0px",
            width: activeMenu === "Reports" ? "90%" : "auto",
            padding: !isOpen && activeMenu === "Reports" ? "10% 0%" : "5% 0%",
            transition: "width 0.3s ease"
          }}>
          <div className="menu-button" onClick={toggleReport}>
            <img src={activeMenu == "Reports" ? activereportsicon : reportsicon} alt="File" className="menu-icon-file" />
            {isOpen && <span style={{ marginLeft: "10px", color: activeMenu === "Reports" ? "#fff" : "gray", }}>Reports</span>}
            {isOpen && (
              <img
                src={isOpenReport ? downArrowIcon : upArrow} alt="downarrow" className="right-arrow"
              />
            )}
            {" "}
          </div>
        </li>
        {isOpenReport && (
          <ul className={!isOpen ? "report-menu-open" : "dropdown-menu"}>
            <li style={{
              background: activeReportMenu === "EODReport" ? "radial-gradient(100% 100% at 0% 0%, rgba(184, 110, 159, 0.12) 0%, rgba(102, 37, 37, 0.12) 100%)" : "#0F090C",
              borderRadius: activeReportMenu === "EODReport" ? "12px" : "0px",
              transition: "width 0.3s ease"
            }}>
              <NavLink to="/layout/eod-report" onClick={() => handleReportMenuClick("EODReport")}><span style={{ color: activeReportMenu === "EODReport" ? "#fff" : "gray" }}>EOD Report</span></NavLink>
            </li>
            <li style={{
              background: activeReportMenu === "MISReport" ? "radial-gradient(100% 100% at 0% 0%, rgba(184, 110, 159, 0.12) 0%, rgba(102, 37, 37, 0.12) 100%)" : "#0F090C",
              borderRadius: activeReportMenu === "MISReport" ? "12px" : "0px",
              transition: "width 0.3s ease"
            }}>
              <NavLink to="/layout/mis-report" onClick={() => handleReportMenuClick("MISReport")}><span style={{ color: activeReportMenu === "MISReport" ? "#fff" : "gray" }}>MIS Report</span></NavLink>
            </li>
          </ul>
        )}
        {userType == "0" && <>
          <li
            style={{
              background: activeMenu === "UserAdd" ? "radial-gradient(100% 100% at 0% 0%, rgba(184, 110, 159, 0.12) 0%, rgba(102, 37, 37, 0.12) 100%)" : "#0F090C",
              borderRadius: activeMenu === "UserAdd" ? "12px" : "0px",
              width: activeMenu === "UserAdd" ? "90%" : "auto",
              padding: !isOpen && activeMenu === "UserAdd" ? "10% 0%" : "5% 0%",
              transition: "width 0.3s ease"
            }}>
            <NavLink
              className="menu-button"
              to="/layout/adduser"
              onClick={() => handleMenuClick("UserAdd")}
            >
              <img
                src={activeMenu === "UserAdd" ? activeUserManagementIcon : userManagementIcon}
                alt="File"
                className="menu-icon-file"
              />{" "}
              {isOpen && (
                <span style={{ marginLeft: "10px", color: activeMenu === "UserAdd" ? "#fff" : "gray", }}>User Management</span>
              )}
            </NavLink>
          </li>
        </>}
        <li className="mt-2" onClick={() => confirmLogout()}
          style={{
            background: activeMenu === "Logout" ? "radial-gradient(100% 100% at 0% 0%, rgba(184, 110, 159, 0.12) 0%, rgba(102, 37, 37, 0.12) 100%)" : "#0F090C",
            borderRadius: activeMenu === "Logout" ? "12px" : "0px",
            width: activeMenu === "Logout" ? "90%" : "auto",
            padding: !isOpen && activeMenu === "Logout" ? "10% 0%" : "5% 0%",
            transition: "width 0.3s ease"
          }}>
          <a
            className="menu-button"
            onClick={() => handleMenuClick("Logout")}
          >
            <img src={activeMenu === "Logout" ? logoutactive : logout} alt="File" className={activeMenu === "Logout" ? "menu-icon-logout-active" : "menu-icon-file"} />{" "}
            {isOpen && <span style={{ marginLeft: "10px", color: activeMenu === "Logout" ? "#fff" : "gray", }}>Logout</span>}
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
