import React from "react";
import ExcelJS from "exceljs";
import excelDownloadIcon from "../../../assets/dashboard/excel-download-icon.png";
import { getFileStatus } from "../../../utils/getFileStatus";

const ExcelMisReport = ({ data }) => {
    const exportExcelFile = async () => {
        if (!Array.isArray(data)) {
            console.error("Data is not an array:", data);
            return;
        }

        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Define columns with headers
        worksheet.columns = [
            { header: 'S.No', key: 's_no', width: 10 },
            { header: 'Bank Name', key: 'organaization_name', width: 30 },
            { header: 'Product', key: 'product_name', width: 15 },
            { header: 'Law Hands Branch Name', key: 'region_name', width: 15 },
            { header: 'Bank Branch Name', key: 'branch_name', width: 15 },
            { header: 'LAN No', key: 'Lan_no', width: 15 },
            { header: 'LH File No', key: 'LH_fileNo', width: 15 },
            { header: 'Customer Name', key: 'customer_name', width: 15 },
            { header: 'Property Owner Name', key: 'property_owner_name', width: 25 },
            { header: '1st Set -Received Doc list', key: 'first_set_doc_list', width: 30 },
            { header: '1st set Doc Submitted Date with Time of Login', key: 'first_doc_submit_date', width: 30 },
            { header: '1st-Queries', key: 'first_query', width: 30 },
            { header: 'Draft/preliminary report Released with Query - Date & Time', key: 'draft_preliminary_report', width: 30 },
            { header: 'Current Pending Queries', key: 'query', width: 30 },
            { header: 'Final Query Doc submission by Bank Date with Time', key: 'query_final', width: 30 },
            { header: 'Final Report released Date with Time', key: 'Final_report_released_date', width: 30 },
            { header: 'Final Status Queries not cleared / Final Report Pending / Report Released', key: 'Final_status', width: 30 },
        ];

        // Apply alignment and color to header cells
        worksheet.getRow(1).eachCell((cell) => {
            cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            cell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'FFFF00' }, // Yellow color
            };
            cell.font = { bold: true, size: 14 };
        });

        const flattenedData = data.map((file, index) => {
            const first_set_doc_list = file.firstset_receivedDocList
                .map(
                    (doc, docIndex) => `${docIndex + 1}. ${doc.mergedValue}`
                )
                .join("\n");
            const query =
                file.query_pending && file.query_pending.length > 0
                    ? file.query_pending
                        .map((query, queryIndex) => `${query.mergedValue}`)
                        .join("\n")
                    : "Nil";
            const first_query =
                file.first_query && file.first_query.length > 0
                    ? file.first_query
                        .map((query, queryIndex) => `${query.mergedValue}`)
                        .join("\n")
                    : "Nil";
            return {
                s_no: file.serial_no,
                organaization_name: file.organaization_name,
                product_name: file.product_name,
                region_name: file.region_name,
                branch_name: file.branch_name,
                Lan_no: file.Lan_no,
                LH_fileNo: file.LH_fileNo,
                customer_name: file.customer_name,
                property_owner_name: file.property_owner_name,
                first_set_doc_list: first_set_doc_list,
                first_doc_submit_date: file?.first_doc_submit_date,
                first_query: first_query,
                draft_preliminary_report: file.draft_preliminary_report,
                query: query,
                query_final: file.query_final ? file.query_final : "Nil",
                Final_report_released_date: file.Final_report_released_date ? file.Final_report_released_date : "Nil",
                Final_status: file.Final_status,
            };
        });

        // Add data rows with custom cell alignment and wrap text
        flattenedData.forEach(item => {
            const row = worksheet.addRow(item);
            row.eachCell((cell) => {
                cell.alignment = { vertical: 'middle', horizontal: 'center', wrapText: true };
            });
        });
        const currentDate = new Date();
        const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1).toString().padStart(2, '0')}-${currentDate.getFullYear()}`;
        // Generate Excel file buffer
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement('a');
        anchor.href = url;
        anchor.download = `Mis Report ${formattedDate}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
    };

    return (
        <>
            <button className="download-btn" onClick={exportExcelFile}>
                <img className="download-image" src={excelDownloadIcon} alt="Download Excel" />
                Download
            </button>
        </>
    );
};

export default ExcelMisReport;
